import React from 'react';
import { func } from 'prop-types';
import { useAuth } from '@bit/medicalwebexperts.mwe-ui.auth-context';
import { Can } from '../../Permissions/Permissions';
import { ROLES } from '../../../constants/user-roles';

const propTypes = {
  children: func.isRequired,
};

const ProfileNavigation = ({ children }) => {
  const { user } = useAuth();

  const items = [
    {
      icon: 'person',
      to: '/profile',
      label: `${
        user.role === ROLES.ADMIN ? 'menu.editProfile' : 'menu.viewProfile'
      }`,
      field: 'general',
      action: user.role === ROLES.ADMIN ? 'read' : 'update',
      subject: 'profile',
      hasError: false,
    },
    {
      icon: 'settings',
      to: '/configurations',
      label: 'menu.configurations',
      action: 'update',
      subject: 'configurations',
      hasError: false,
    },
    {
      icon: 'settings',
      to: '/profile/settings',
      label: 'menu.settings',
      action: 'update',
      subject: 'settings',
      hasError: false,
    },
    {
      icon: 'lock',
      to: '/profile/password',
      label: 'menu.chgPass',
      action: 'update',
      subject: 'profile',
      field: 'password',
      hasError: false,
    },
  ];

  return items.map(({ field, action, subject, ...rest }) => (
      rest.disabled !== true && (
        <Can key={rest.to} I={action} a={subject}>
          {() => children(rest)}
        </Can>
      )
    ));
};

ProfileNavigation.propTypes = propTypes;

export default ProfileNavigation;
