import React from 'react';
import { node } from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';

import styled from '@emotion/styled';
import css from '@styled-system/css';
import Flex from '@bit/medicalwebexperts.mwe-ui.flex';
import Card from '@bit/medicalwebexperts.mwe-ui.card';
import Box from '@bit/medicalwebexperts.mwe-ui.box';
import Typography from '@bit/medicalwebexperts.mwe-ui.typography';
import Image from '@bit/medicalwebexperts.mwe-ui.image';
import Link from '../../Link/Link';
import logo from '../../../assets/images/login-logo.png';

const propTypes = {
  children: node.isRequired,
};

const EmptyCard = styled(Card)(
  css({
    borderRadius: 'lg',
    width: '100%',
  }),
);

const Content = styled('div')(
  css({
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: '100vh',
    bg: 'gray.1000',
  }),
);

const LeftPanel = () => {
  const { t } = useTranslation();

  return (
    <Flex height="100%" bg="gray.1000" p="10">
      <Box>
        <Image
          mb="5"
          width="100%"
          maxWidth="300px"
          src={logo}
          alt={t('RegistrationLayout.altLogo')}
        />
        <Typography mb="3" fontWeight="medium">
          {t('RegistrationLayout.register')}
        </Typography>
        <Typography fontSize="sm" color="gray.700">
          {t('RegistrationLayout.leftText')}
        </Typography>
      </Box>
    </Flex>
  );
};

const RegistrationLayout = ({ children }) => (
    <Content p={{ _: '3', lg: '10' }}>
      <EmptyCard>
        <Card.Body
          display="grid"
          gridTemplateColumns={{ _: '1fr', lg: '1fr 3fr' }}
          p="0"
        >
          <Box>
            <LeftPanel />
          </Box>
          <Box p="3">{children}</Box>
        </Card.Body>
      </EmptyCard>
      <Typography width="100%" textAlign="center" fontSize="sm" my="3">
        <Trans i18nKey="RegistrationLayout.loginLink">
          If you have an account
          <Link to="/account/login" color="primary.main">
            Login here
          </Link>
        </Trans>
      </Typography>
    </Content>
  );

RegistrationLayout.propTypes = propTypes;

export default RegistrationLayout;
