import React from 'react';
import { useTranslation } from 'react-i18next';
import { node } from 'prop-types';
import styled from '@emotion/styled';
import css from '@styled-system/css';
import AppBar from '@bit/medicalwebexperts.mwe-ui.app-bar';
import List from '@bit/medicalwebexperts.mwe-ui.list';
import { NavLink } from 'react-router-dom';
import { useUp } from '@bit/medicalwebexperts.mwe-ui.utils.styled';
import BaseSidebar, {
  useSidebarState,
} from '@bit/medicalwebexperts.mwe-ui.sidebar';
import IconButton from '@bit/medicalwebexperts.mwe-ui.icon-button';
import Box from '@bit/medicalwebexperts.mwe-ui.box';
import { MdMenu } from 'react-icons/md';
import { useAuthDispatch } from '@bit/medicalwebexperts.mwe-ui.auth-context';
import ProfileDropdown from './ProfileDropdown';
import AppBarItem from './AppBarItem';
import logo from '../../../assets/images/appbar-logo.png';
import Sidebar from './Sidebar';
import { Can } from '../../Permissions/Permissions';
import useIdleLogout from '../../../hooks/useIdleLogout';
import IdleLogoutModal from '../../Common/IdleLogoutModal';
import Config from '../../../config/index';

const propTypes = {
  children: node.isRequired,
};

const Content = styled(Box)(
  css({
    maxWidth: 1200,
    margin: '0px auto',
    mt: 7,
    p: 3,
  }),
);

const Logo = styled.img({
  top: '10px',
  left: '103px',
  width: '130px',
  height: '44px',
  ml: 10,
});
const Toolbar = styled.div(
  css({
    alignItems: 'center',
    display: 'flex',
    minHeight: 16,
  }),
);
const ListItem = styled(List.Item)(
  css({
    width: 'auto',
    alignItems: 'flex-start',
    display: 'flex',
    border: 'none',
    borderRightColor: 'transparent',
    color: 'black',
    cursor: 'pointer',
    fontFamily: 'body',
    fontSize: 'sm',
    fontWeight: 'medium',
    outline: 'none',
    px: 4,
    py: 5,
    ml: 10,
    transition: 'background-color .5s ease',
    '&.ListItem--active': {
      color: 'text',
      borderBottomColor: 'primary.main',
      borderBottomStyle: 'solid',
      borderBottomWidth: '6px',
      svg: {
        color: 'white',
      },
    },
  }),
);

const LoggedInLayout = ({ children }) => {
  const { logout } = useAuthDispatch();
  const showSidebar = !useUp('lg');
  const sidebar = useSidebarState({ animated: true });
  const { t } = useTranslation();

  const MenuItems = AppBarItem.map(({ label, to, action, subject }) => (
      <Can key={to} I={action} a={subject}>
        <ListItem
          to={to}
          key={to}
          as={NavLink}
          activeClassName="ListItem--active"
          justifyContent="center"
        >
          {t(label)}
        </ListItem>
      </Can>
    ));

  const handleLogout = () => {
    logout();
  };
  const [remainingSecs, isTimingOut] = useIdleLogout({
    countdowntimer: Config.portal.iddleCountDown,
    idleTimer: Config.portal.iddleTime,
    handleLogout,
  });

  return (
    <>
      {showSidebar && (
        <Sidebar
          expand="xl"
          state={sidebar}
          ariaLabel="Main Menu"
          sidebarItems={AppBarItem}
        />
      )}
      <AppBar
        backgroundColor="white"
        boxShadow="0 2px 6px rgba(0, 0, 0, 0.1)"
        px="0"
        display="context"
        position="static"
      >
        <Box p="3" width="100%" maxWidth="1200px" margin="0px auto">
          <Toolbar>
            {showSidebar && (
              <BaseSidebar.Disclosure {...sidebar}>
                {(props) => (
                  <IconButton
                    size="lg"
                    variant="text"
                    variantColor="primary"
                    {...props}
                  >
                    <MdMenu />
                  </IconButton>
                )}
              </BaseSidebar.Disclosure>
            )}

            <Logo
              src={logo}
              srcSet={`${logo} 1x`}
              alt="MicroGen"
              flexGrow="1"
              to="/"
            />
            <List
              as="nav"
              flexGrow="1"
              display="flex"
              alignItems="center"
              justifyContent="flex-start"
            >
              {!showSidebar && MenuItems}
            </List>
            <ProfileDropdown />
          </Toolbar>
        </Box>
      </AppBar>
      <Content>{children}</Content>
      <IdleLogoutModal status={isTimingOut} remainingSecs={remainingSecs} />
    </>
  );
};

LoggedInLayout.propTypes = propTypes;

export default LoggedInLayout;
