export const LANGUAGES = {
  ES: 'es',
  EN: 'en',
};

export const LANGUAGES_LABELS = {
  [LANGUAGES.EN]: 'English',
  [LANGUAGES.ES]: 'Español',
};

export const LANGUAGES_LIST = Object.entries(LANGUAGES_LABELS).map(
  ([value, label]) => ({
    value,
    label,
  }),
);
