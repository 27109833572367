export const ROLES = {
  ADMIN: 'ROLE_ADMIN',
  PATIENT: 'ROLE_PATIENT',
};

export const ROLES_LABELS = {
  [ROLES.ADMIN]: 'Administrator',
  [ROLES.PATIENT]: 'Patient',
};

export const ROLES_LIST = Object.entries(ROLES_LABELS).map(
  ([value, label]) => ({
    value,
    label,
  }),
);
