export default [
  {
    external: false,
    label: 'menu.Users',
    to: '/users',
    action: 'list',
    subject: 'users',
  },
  {
    external: false,
    label: 'menu.LatestReq',
    to: '/requisitions',
    action: 'find',
    subject: 'requisitions',
  },
  {
    external: false,
    label: 'menu.LatestRes',
    to: '/test-results',
    action: 'list',
    subject: 'test-results',
  },
  // {
  //   external: false,
  //   label: 'menu.historic',
  //   to: '/historic-results',
  //   action: 'list',
  //   subject: 'historic-results',
  // },
  {
    external: false,
    label: 'menu.logs',
    to: '/activity-logs',
    action: 'find',
    subject: 'activity-logs',
  },
];
