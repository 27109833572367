import React, { useEffect, Suspense, lazy } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { Helmet } from 'react-helmet';
import ProgressBar from '@bit/medicalwebexperts.mwe-ui.progress-bar';
import { useTranslation } from 'react-i18next';
import ErrorBoundary from '../ErrorBoundary/ErrorBoundary';
import LoggedOutLayout from '../Layout/LoggedOutLayout/LoggedOutLayout';
import LoggedInLayout from '../Layout/LoggedInLayout/LoggedInLayout';
import { LANGUAGES } from '../../constants/languages';

import PublicRoute from '../Routing/PublicRoute';
import PrivateRoute from '../Routing/PrivateRoute';
import RegistrationLayout from '../Layout/LoggedOutLayout/RegistrationLayout';

const LoginPage = lazy(() => import('../../pages/Account/Login/Login'));
const ForgotPasswordPage = lazy(() =>
  import('../../pages/Account/ForgotPassword/ForgotPassword'),
);
const SetPasswordPage = lazy(() =>
  import('../../pages/Account/SetPassword/SetPassword'),
);
const HomePage = lazy(() => import('../../pages/Home'));
const ProfilePage = lazy(() => import('../../pages/Profile/Profile'));
const NotFoundPage = lazy(() => import('../../pages/404'));
const ListsUsers = lazy(() => import('../../pages/Admin/Users/Lists'));
const CreateUser = lazy(() => import('../../pages/Admin/Users/CreateUser'));
const Requisitions = lazy(() =>
  import('../../pages/Admin/Requisitions/Requisitions'),
);

const ActivityLog = lazy(() => import('../../pages/ActivityLog/ActivityLog'));
const InformationBanner = lazy(() =>
  import('../../pages/Configurations/InformationBanner'),
);

const TestResults = lazy(() => import('../../pages/TestResults/TestResults'));
const QrPdf = lazy(() => import('../../pages/TestResults/QrPdf'));

// const HistoricResults = lazy(() =>
//   import('../../pages/TestResults/HistoricResults'),
// );

const ScrollToTop = () => {
  const { i18n } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
    const url = new URL(window.location.href);
    const urlLang = url.searchParams.get('language');
    const language = [LANGUAGES.EN, LANGUAGES.ES].includes(urlLang) ? urlLang : LANGUAGES.EN;
    i18n.changeLanguage(language);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

const Loader = (props) => (
  <Suspense
    {...props}
    fallback={
      <ProgressBar
        classes={{
          root: { position: 'absolute', top: 0, left: 0 },
        }}
        size="sm"
      />
    }
  />
);

const App = () => (
    <Router>
      <Helmet titleTemplate="%s - MicroGen DX - Patient Portal" />
      <ScrollToTop />
      <ErrorBoundary>
        <Switch>
          <PublicRoute path="/account">
            <LoggedOutLayout>
              <Loader>
                <Switch>
                  <Route path="/account/login">
                    <LoginPage />
                  </Route>
                  <Route path="/account/forgot-password">
                    <ForgotPasswordPage />
                  </Route>
                  <Route path="/account/set-password/:uid/:token">
                    <SetPasswordPage />
                  </Route>
                  <Redirect to="/account/login" />
                </Switch>
              </Loader>
            </LoggedOutLayout>
          </PublicRoute>

          <PublicRoute path="/qr-pdf">
              <Loader>
                <Switch>
                  <Route path="/qr-pdf/:id">
                    <QrPdf />
                  </Route>
                </Switch>
              </Loader>
          </PublicRoute>

          <PublicRoute path="/registration">
            <RegistrationLayout>
              <Loader>
                <Switch>
                  <Route path="/registration">
                    <CreateUser selfRegister />
                  </Route>
                </Switch>
              </Loader>
            </RegistrationLayout>
          </PublicRoute>

          <PrivateRoute path="/">
            <LoggedInLayout>
              <Loader>
                <Switch>
                  <PrivateRoute path="/" exact>
                    <HomePage />
                  </PrivateRoute>
                  <PrivateRoute path="/profile">
                    <ProfilePage />
                  </PrivateRoute>
                  <PrivateRoute path="/users" exact>
                    <ListsUsers />
                  </PrivateRoute>
                  <PrivateRoute path="/users/create" exact>
                    <CreateUser />
                  </PrivateRoute>
                  <PrivateRoute path="/users/edit">
                    <CreateUser />
                  </PrivateRoute>
                  <PrivateRoute path="/requisitions" exact>
                    <Requisitions />
                  </PrivateRoute>
                  <PrivateRoute path="/test-results" exact>
                    <TestResults />
                  </PrivateRoute>
                  {/* <PrivateRoute path="/historic-results" exact>
                    <HistoricResults />
                  </PrivateRoute> */}
                  <PrivateRoute path="/activity-logs" exact>
                    <ActivityLog />
                  </PrivateRoute>
                  <PrivateRoute path="/configurations" exact>
                    <InformationBanner />
                  </PrivateRoute>
                  <PrivateRoute path="*">
                    <NotFoundPage />
                  </PrivateRoute>
                </Switch>
              </Loader>
            </LoggedInLayout>
          </PrivateRoute>
        </Switch>
      </ErrorBoundary>
    </Router>
  );

export default App;
