import feathers from '@feathersjs/client';
import axios from 'axios';

const client = feathers();
const rest = feathers.rest(process.env.REACT_APP_API_BASE_URL);

client.configure(rest.axios(axios));
client.configure(feathers.authentication());

const responseSuccessHandler = (response) => response;

const responseErrorHandler = (error) => {
  if (error.response.status === 401) {
    if (window.location.href.indexOf('account/login') === -1) {
      // window.location.replace('/account/login');
    }
  }

  return Promise.reject(error);
};

axios.interceptors.response.use(
  (response) => responseSuccessHandler(response),
  (error) => responseErrorHandler(error),
);

export default client;
