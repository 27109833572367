import React, { useEffect } from 'react';
import { bool, number } from 'prop-types';
import Modal, { useModalState } from '@bit/medicalwebexperts.mwe-ui.modal';
import Typography from '@bit/medicalwebexperts.mwe-ui.typography';
import Divider from '@bit/medicalwebexperts.mwe-ui.divider';

const propTypes = {
  remainingSecs: number,
  status: bool.isRequired,
};

const defaultProps = {
  remainingSecs: 0,
};

const IdleLogoutModal = ({ remainingSecs, status }) => {
  const modalState = useModalState();

  const evaluateModal = () => (status ? modalState.show() : modalState.hide());

  useEffect(
    evaluateModal,
    // eslint-disable-next-line
    [status],
  );

  return (
    <Modal state={modalState} ariaLabel="Zoom Modal" withBackdrop>
      <Typography variant="subtitle1">Is anyone there?</Typography>
      <Divider />
      <Modal.Body>
        <Typography variant="body1" paragraph>
          For security reasons, you will be loged out of the application in
          {` ${remainingSecs}`} seconds.
        </Typography>
      </Modal.Body>
    </Modal>
  );
};

IdleLogoutModal.propTypes = propTypes;
IdleLogoutModal.defaultProps = defaultProps;
export default IdleLogoutModal;
