import React from 'react';
import { node } from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { useAuth } from '@bit/medicalwebexperts.mwe-ui.auth-context';
import { ROLES } from '../../constants/user-roles';

const propTypes = {
  children: node.isRequired,
};

const redirectToMap = {
  [ROLES.ADMIN]: '/users',
  [ROLES.PATIENT]: '/test-results',
};

const PublicRoute = ({ children, ...props }) => {
  const { user, isLoggedIn } = useAuth();

  return (
    <Route
      {...props}
      render={() =>
        !isLoggedIn ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: redirectToMap[user.role] || '/profile',
            }}
          />
        )
      }
    />
  );
};

PublicRoute.propTypes = propTypes;

export default PublicRoute;
