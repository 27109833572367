import i18n from 'i18next';
import { setLocale } from 'yup';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import es from './assets/locale/es.json';
import en from './assets/locale/en.json';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init(
    {
      debug: true,
      fallbackLng: 'en',
      load: 'languageOnly',
      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },
      resources: {
        en,
        es,
      },
    },
    () => {
      setLocale({
        mixed: {
          required: 'validations.mixed.required',
        },
        string: {
          email: 'validations.string.email',
          min: ({ path, min }) => ({
            key: 'validations.string.min',
            values: { path, min },
          }),
          max: ({ path, max }) => ({
            key: 'validations.string.max',
            values: { path, max },
          }),
          length: ({ path, length }) => ({
            key: 'validations.string.length',
            values: { path, length },
          }),
        },
        array: {
          length: ({ path, length }) => ({
            key: 'validations.array.length',
            values: { path, length },
          }),
        },
        number: {
          min: ({ min }) => ({
            key: 'validations.number.min',
            values: { min },
          }),
          max: ({ max }) => ({
            key: 'validations.number.max',
            values: { max },
          }),
        },
      });
    },
  );

export default i18n;
