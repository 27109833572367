import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Maintenance from '../components/Common/Maintenance';

const propTypes = {
  children: PropTypes.node.isRequired,
};

const MaintenanceProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [information, setInformation] = useState({
    offline: false,
    offline_from: '',
    offline_until: '',
  });

  useEffect(() => {
    const load = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_MAINTENANCE_URL);

        if (response.ok) {
          const data = await response.json();
          setInformation(data);
        }
      } catch {
        // do nothing
      } finally {
        setIsLoading(false);
      }
    };

    load();
  }, []);

  if (isLoading) {
    return null;
  }

  if (information.offline) {
    return (
      <Maintenance
        from={information.offline_from}
        to={information.offline_until}
      />
    );
  }

  return children;
};

MaintenanceProvider.propTypes = propTypes;

export default MaintenanceProvider;
