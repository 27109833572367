import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { node } from 'prop-types';
import { useAuth } from '@bit/medicalwebexperts.mwe-ui.auth-context';

const propTypes = {
  children: node.isRequired,
};

const PrivateRoute = ({ children, ...props }) => {
  const { isLoggedIn } = useAuth();

  return (
    <Route
      {...props}
      render={({ location }) =>
        isLoggedIn ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/account/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

PrivateRoute.propTypes = propTypes;

export default PrivateRoute;
