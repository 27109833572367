import {
  MdEmail,
  MdLock,
  MdPerson,
  MdTune,
  MdKeyboardArrowDown,
  MdKeyboardArrowUp,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  MdHighlightOff,
  MdDateRange,
  MdAccountBox,
  MdPhone,
  MdSettings,
  MdPrint,
  MdOutlineDownloadForOffline,
  MdLink,
} from 'react-icons/md';

const icons = {
  lock: MdLock,
  email: MdEmail,
  person: MdPerson,
  tune: MdTune,
  arrowDown: MdKeyboardArrowDown,
  arrowUp: MdKeyboardArrowUp,
  arrowLeft: MdKeyboardArrowLeft,
  arrowRight: MdKeyboardArrowRight,
  close: MdHighlightOff,
  date: MdDateRange,
  account: MdAccountBox,
  phone: MdPhone,
  settings: MdSettings,
  MdKeyboardArrowDown,
  print: MdPrint,
  download: MdOutlineDownloadForOffline,
  link: MdLink,
};

export default icons;
