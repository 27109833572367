import React from 'react';
import ReactDOM from 'react-dom';
import ThemeProvider from '@bit/medicalwebexperts.mwe-ui.theme-provider';
import Normalize from '@bit/medicalwebexperts.mwe-ui.normalize';
import AuthProvider from '@bit/medicalwebexperts.mwe-ui.auth-context';
import SnackbarProvider from '@bit/medicalwebexperts.mwe-ui.snackbar-context';
import PermissionsProvider from './components/Permissions/Permissions';
import client from './services/client';
import theme from './themes/preset';
import App from './components/App/App';
import MaintenanceProvider from './contexts/MaintenanceProvider';
import * as serviceWorker from './serviceWorker';
import 'typeface-roboto';
import './i18n';

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Normalize />
      <MaintenanceProvider>
        <AuthProvider client={client} storageKey="feathers-jwt">
          <PermissionsProvider>
            <SnackbarProvider>
              <App />
            </SnackbarProvider>
          </PermissionsProvider>
        </AuthProvider>
      </MaintenanceProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
