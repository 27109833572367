import preset from '@bit/medicalwebexperts.mwe-ui.themes.preset';
import icons from './icons';

export default {
  ...preset,
  fonts: {
    ...preset.fonts,
    body:
      'Roboto, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", sans-serif',
    heading:
      'Roboto, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", sans-serif',
  },
  colors: {
    ...preset.colors,
    gray: {
      ...preset.colors.gray,
      1000: '#F9F9F9',
    },
    background: '#FFFFFF',
    primary: {
      moreLight: '#E4F0F7',
      light: '#01A8EC',
      main: '#0883C7',
      dark: '#1B2E7A',
    },
    secondary: {
      light: '#FFCB0F',
      main: '#F68717',
      dark: '#F1441C',
    },
  },
  sizes: {
    ...preset.sizes,
    sidebar: '240px',
  },
  icons,
};
