import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  useAuth,
  useAuthDispatch,
} from '@bit/medicalwebexperts.mwe-ui.auth-context';
import { NavLink } from 'react-router-dom';

import Dropdown, {
  useDropdownState,
} from '@bit/medicalwebexperts.mwe-ui.dropdown';
import Chip from '@bit/medicalwebexperts.mwe-ui.chip';
import Icon from '@bit/medicalwebexperts.mwe-ui.icon';
import Avatar from '@bit/medicalwebexperts.mwe-ui.avatar';
import ProfileNavigation from './ProfileNavigation';

const itemClasses = {
  root: {
    color: 'primary.main',
    textTransform: 'capitalize',
    justifyContent: 'flex-start',
    '&.ProfileDropdown--active': {
      color: 'gray.600',
    },
    svg: {
      color: 'primary.light',
    },
  },
};

const chipClasses = {
  chip: {
    size: '18px',
    minWidth: 0,
    border: '2px solid',
    boderColor: 'white',
  },
};

const ProfileDropdown = () => {
  const { user } = useAuth();
  const { logout } = useAuthDispatch();
  const dropdown = useDropdownState();
  const { t } = useTranslation();

  return (
    <Dropdown state={dropdown}>
      <Dropdown.Toggle
        render={(toggleProps) => (
          <Chip
            {...toggleProps}
            classes={chipClasses}
            content={<Icon name="arrowDown" size={4} color="white" />}
            position="bottomRight"
            overlap="circle"
            color="primary"
          >
            <Avatar bg="primary.main" name={user.name} size="xs" />
          </Chip>
        )}
      />
      <Dropdown.Menu aria-label="Dropdown menu" bg="white">
        <ProfileNavigation>
          {({ to, label, icon }) => (
            <Dropdown.Item
              exact
              to={to}
              key={to}
              as={NavLink}
              classes={itemClasses}
              onClick={dropdown.hide}
              activeClassName="ProfileDropdown--active"
            >
              <Icon name={icon} size={19} mr={2} />
              {t(label)}
            </Dropdown.Item>
          )}
        </ProfileNavigation>
        <Dropdown.Item classes={itemClasses} onClick={logout}>
          <Icon name="exit" size={19} mr={2} />
          {t('menu.logout')}
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ProfileDropdown;
