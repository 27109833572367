import React, { useState, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { node } from 'prop-types';
import styled from '@emotion/styled';
import css from '@styled-system/css';
import Card from '@bit/medicalwebexperts.mwe-ui.card';
import Typography from '@bit/medicalwebexperts.mwe-ui.typography';
import Alert from '@bit/medicalwebexperts.mwe-ui.alert';
import { useSnackbarDispatch } from '@bit/medicalwebexperts.mwe-ui.snackbar-context';
import Link from '../../Link/Link';
import client from '../../../services/client';
import logo from '../../../assets/images/login-logo.png';

const propTypes = {
  children: node.isRequired,
};

const Main = styled.main(
  css({
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: '90vh',
    width: '100%',
  }),
);

const LoggedOutCard = styled(Card)(
  css({
    borderRadius: 'lg',
    maxWidth: '340px',
    minWidth: '300px',
    minHeight: '275px',
    position: 'relative',
    overflow: 'hidden',
    width: '100%',
  }),
);

const Logo = styled.img(
  css({
    display: 'block',
    mb: 8,
    mx: 'auto',
    width: '100%',
  }),
);

const LoggedOutLayout = ({ children }) => {
  const [banner, setBanner] = useState(undefined);
  const snackbar = useSnackbarDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    async function fetchData() {
      try {
        const query = {};
        query.name = 'informationBanner';
        query.enabled = 1;

        const existingConfig = await client.service('configurations').find({
          query,
        });
        if (existingConfig.total > 0) {
          await setBanner(existingConfig.data[0].data);
        }
      } catch (e) {
        snackbar.open({
          message: t('loggedOutLayout.error'),
          status: 'error',
        });
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Main p={{ _: '3', lg: '10' }}>
        {banner && (
          <Alert
            width="100%"
            maxWidth="780px"
            my="5"
            status="warning"
            bg="primary.light"
            onClose={() => {
              setBanner(undefined);
            }}
            showIcon
          >
            {banner}
          </Alert>
        )}
        <LoggedOutCard>
          <Card.Body py={8} textAlign="center">
            <Logo src={logo} alt="MicroGen" />
            {children}
          </Card.Body>
        </LoggedOutCard>
        <Typography fontSize="sm" mt="3">
          <Trans i18nKey="loggedOutLayout.register">
            If you dont have an account
            <Link to="/registration" color="primary.main">
              Register here
            </Link>
          </Trans>
        </Typography>
      </Main>
    </>
  );
};

LoggedOutLayout.propTypes = propTypes;

export default LoggedOutLayout;
