import React from 'react';
import { arrayOf, shape } from 'prop-types';
import css from '@styled-system/css';
import styled from '@emotion/styled';
import List from '@bit/medicalwebexperts.mwe-ui.list';
import BaseSidebar from '@bit/medicalwebexperts.mwe-ui.sidebar';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import logo from '../../../assets/images/appbar-logo.png';
import { Can } from '../../Permissions/Permissions';

const propTypes = {
  state: shape({}).isRequired,
  sidebarItems: arrayOf(shape({})).isRequired,
};

const Wrapper = styled.div({
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100%',
});

const Logo = styled.img(
  css({
    width: "100%",
  }),
);

const LogoWrapper = styled.div(
  css({
    padding: "20px",
  }),
);

const ListItem = styled(List.Item)(
  css({
    border: 'none',
    borderRightColor: 'transparent',
    borderRightStyle: 'solid',
    borderRightWidth: '6px',
    color: 'black',
    cursor: 'pointer',
    fontFamily: 'body',
    fontSize: 'sm',
    fontWeight: 'medium',
    outline: 'none',
    px: 4,
    py: 5,
    transition: 'background-color .5s ease',
    '&:hover': {
      bg: 'blackAlpha.100',
    },
    '&.ListItem--active': {
      color: 'secondary.main',
      bg: 'blackAlpha.300',
      borderRightColor: 'secondary.main',
      svg: {
        color: 'white',
      },
    },
  }),
);

const sidebarStyles = {
  root: {
    border: 'none',
    bg: 'white',
  },
};

const Sidebar = (props) => {
  const { state, sidebarItems } = props;
  const { t } = useTranslation();
  const items = sidebarItems.map(({ label, to, subject, action }) => (
      <Can key={to} I={action} a={subject}>
        <ListItem
          exact
          key={to}
          to={to}
          as={NavLink}
          onClick={state.hide}
          activeClassName="ListItem--active"
        >
          {t(label)}
        </ListItem>
      </Can>
    ));

  return (
    <BaseSidebar {...props} classes={sidebarStyles}>
      <Wrapper>
      <LogoWrapper>
        <Logo
          src={logo}
          srcSet={`${logo} 1x`}
          alt="MicroGen"
          to="/"
        />
        </LogoWrapper>
        <List as="nav" flexGrow="1">
          {items}
        </List>
      </Wrapper>
    </BaseSidebar>
  );
};

Sidebar.propTypes = propTypes;

export default Sidebar;
